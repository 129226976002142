import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faComments,
  faComment,  // General feedback
  faBug,      // Bug report
  faStar,     // Feature request
  faLifeRing,  // Support
  faHandshake  // Sales
} from '@fortawesome/free-solid-svg-icons';
import { submitFeedback } from '../utils/feedbackApi';
import { AuthContext } from '../utils/AuthContext';
import DOMPurify from 'dompurify';
import { Turnstile } from '@marsidev/react-turnstile';
import { ThemeContext } from '../utils/ThemeContext';

const FeedbackModal = () => {
  const { session } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [feedbackType, setFeedbackType] = useState('feedback');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState(null);
  const { theme } = useContext(ThemeContext);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const turnstileRef = useRef(null);

  const validateFeedbackType = (type) => {
    const allowedTypes = ['feedback', 'bug', 'feature', 'support', 'sales'];
    return allowedTypes.includes(type) ? type : 'feedback';
  };

  const sanitizeMessage = (message) => {
    const sanitized = DOMPurify.sanitize(message, { 
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: []
    });

    const trimmed = sanitized.trim();
    if (trimmed.length < 10) {
      throw new Error('Message must be at least 10 characters long');
    }
    if (trimmed.length > 2000) {
      throw new Error('Message must be less than 2000 characters');
    }

    return trimmed;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!turnstileToken) {
      setFeedbackStatus({
        type: 'error',
        message: 'Please complete the security check'
      });
      return;
    }

    setIsSending(true);
    setFeedbackStatus(null);

    try {
      if (!session?.user?.id) {
        throw new Error('You must be logged in to submit feedback');
      }

      const validatedType = validateFeedbackType(feedbackType);
      const sanitizedMessage = sanitizeMessage(feedbackMessage);

      await submitFeedback({
        type: validatedType,
        message: sanitizedMessage,
        userEmail: session.user.email,
        userId: session.user.id,
        turnstileToken,
        session
      });

      setFeedbackStatus({ type: 'success', message: 'Feedback sent successfully!' });
      setTimeout(() => {
        setShowModal(false);
        setFeedbackMessage('');
        setFeedbackStatus(null);
      }, 2000);

      turnstileRef.current?.reset();
      setTurnstileToken(null);
    } catch (error) {
      console.error('Error sending feedback:', error);
      setFeedbackStatus({ 
        type: 'error', 
        message: error.message || 'Failed to send feedback. Please try again.' 
      });
    } finally {
      setIsSending(false);
    }
  };

  const onTurnstileSuccess = (token) => {
    setTurnstileToken(token);
  };

  const feedbackTypes = [
    { value: 'feedback', label: 'General Feedback', icon: faComment },
    { value: 'bug', label: 'Report a Bug', icon: faBug },
    { value: 'feature', label: 'Feature Request', icon: faStar },
    { value: 'support', label: 'Support Request', icon: faLifeRing },
    { value: 'sales', label: 'Sales Inquiry', icon: faHandshake }
  ];

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="btn btn-ghost btn-circle"
        aria-label="Send feedback"
      >
        <FontAwesomeIcon icon={faComments} />
      </button>

      <dialog className={`modal ${showModal ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg mb-4">
            <FontAwesomeIcon 
              icon={feedbackTypes.find(t => t.value === feedbackType)?.icon || faComments} 
              className="mr-2 text-primary"
            />
            Send Feedback
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text">Type</span>
              </label>
              <select 
                className="select select-bordered w-full"
                value={feedbackType}
                onChange={(e) => setFeedbackType(validateFeedbackType(e.target.value))}
              >
                {feedbackTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              <label className="label">
                <span className="label-text-alt flex items-center text-base-content/60">
                  <FontAwesomeIcon 
                    icon={feedbackTypes.find(t => t.value === feedbackType)?.icon} 
                    className="mr-2"
                  />
                  {feedbackTypes.find(t => t.value === feedbackType)?.label}
                </span>
              </label>
            </div>
            
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text">Message</span>
                <span className="label-text-alt">
                  {feedbackMessage.length}/2000
                </span>
              </label>
              <textarea 
                className="textarea textarea-bordered h-24"
                placeholder="Your message here..."
                value={feedbackMessage}
                onChange={(e) => setFeedbackMessage(e.target.value)}
                maxLength={2000}
                required
                aria-label="Feedback message"
              ></textarea>
              <label className="label">
                <span className="label-text-alt text-base-content/60">
                  Minimum 10 characters
                </span>
              </label>
            </div>

            {feedbackStatus && (
              <div className={`alert ${feedbackStatus.type === 'success' ? 'alert-success' : 'alert-error'} mb-4`}>
                <span>{feedbackStatus.message}</span>
              </div>
            )}

            <div className="flex justify-center mb-4">
              <Turnstile
                ref={turnstileRef}
                siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
                onSuccess={onTurnstileSuccess}
                onError={() => {
                  setFeedbackStatus({
                    type: 'error',
                    message: 'Security check failed. Please try again.'
                  });
                }}
                options={{
                  theme: theme === 'dark' ? 'dark' : 'light',
                }}
              />
            </div>

            <div className="modal-action">
              <button 
                type="button" 
                className="btn" 
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className={`btn btn-primary ${isSending ? 'loading' : ''}`}
                disabled={isSending || feedbackMessage.length < 10 || !turnstileToken}
              >
                {isSending ? 'Sending...' : 'Send Feedback'}
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => setShowModal(false)}>close</button>
        </form>
      </dialog>
    </>
  );
};

export default FeedbackModal; 