import React, { useState, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import DOMPurify from 'dompurify';
import { AuthContext } from '../utils/AuthContext';
import { validateUsername } from '../utils/validateUsername';

const UserDetailsSection = () => {
  const { userData, setUserData } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    if (userData) {
      setName(userData.name || '');
      setAvatarUrl(userData.avatar_url || '');
    }
  }, [userData]);

  const sanitizeInput = (input) => {
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    const doc = new DOMParser().parseFromString(sanitized, 'text/html');
    return doc.body.textContent || "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedName = sanitizeInput(name);
    const sanitizedAvatarUrl = sanitizeInput(avatarUrl);

    if (sanitizedName !== name || sanitizedAvatarUrl !== avatarUrl) {
      showToast('Some potentially unsafe content was removed from your input.', 'warning');
    }

    // Validate name
    const error = await validateUsername(sanitizedName, userData.id);
    if (error) {
      setNameError(error);
      return;
    }

    // Update using user_profiles_editable view
    const { data, error: updateError } = await supabase
      .from('user_profiles_editable')
      .update({ 
        name: sanitizedName, 
        avatar_url: sanitizedAvatarUrl 
      })
      .eq('id', userData.id);

    if (updateError) {
      console.error('Error updating user details:', updateError);
      showToast('Error updating user details.', 'error');
    } else {
      setNameError('');
      if (setUserData) {
        setUserData({ 
          ...userData, 
          name: sanitizedName, 
          avatar_url: sanitizedAvatarUrl 
        });
      }
      setName(sanitizedName);
      setAvatarUrl(sanitizedAvatarUrl);
      showToast('User details updated successfully.', 'success');
    }
  };

  const showToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => {
      setToastMessage('');
      setToastType('');
    }, 3000);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white">User Details</h2>
      <p className="text-gray-600 dark:text-gray-400">Update your personal information here.</p>
      
      {/* Avatar display */}
      <div className="flex items-center space-x-4">
        <div className="avatar">
          <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
            {avatarUrl ? (
              <img src={avatarUrl} alt="User avatar" />
            ) : (
              <div className="bg-neutral-focus text-neutral-content rounded-full w-24 h-24 flex items-center justify-center text-3xl font-bold">
                {name ? name[0].toUpperCase() : '?'}
              </div>
            )}
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold">{name || 'Anonymous User'}</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {avatarUrl ? '' : 'No avatar set'}
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`input input-bordered w-full ${nameError ? 'input-error' : ''}`}
          />
          {nameError && (
            <label className="label">
              <span className="label-text-alt text-error">{nameError}</span>
            </label>
          )}
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Avatar URL</span>
          </label>
          <input
            type="text"
            value={avatarUrl}
            onChange={(e) => setAvatarUrl(e.target.value)}
            className="input input-bordered w-full"
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Save Changes
        </button>
      </form>

      {/* Toast message */}
      {toastMessage && (
        <div className={`alert alert-${toastType} mt-4`}>
          <div className="flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 mx-2 stroke-current">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <label>{toastMessage}</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetailsSection;
