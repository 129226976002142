import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faFont, faCalendar, faSearch, faCheck, faList, faFile, faQuestion, faSortNumericUp, faPhone, faLink, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const FormDataDisplay = ({ formDetails }) => {
  const getInputIcon = (type) => {
    switch (type) {
      case 'email':
        return faEnvelope;
      case 'password':
        return faLock;
      case 'text':
        return faFont;
      case 'date':
        return faCalendar;
      case 'search':
        return faSearch;
      case 'checkbox':
        return faCheck;
      case 'select':
        return faList;
      case 'file':
        return faFile;
      case 'number':
        return faSortNumericUp;
      case 'tel':
        return faPhone;
      case 'url':
        return faLink;
      default:
        return faQuestion;
    }
  };

  const renderFormInput = (input) => {
    const commonProps = {
      className: "input input-bordered w-full",
      value: input.value || '',
      readOnly: true
    };

    if (input.type === 'hidden') {
      return (
        <div className="form-control">
          <label className="label">
            <span className="label-text">
              <FontAwesomeIcon icon={faEyeSlash} className="mr-1" />
              Hidden Field: {input.name}
            </span>
          </label>
          <input type="text" {...commonProps} className="input input-bordered w-full" />
        </div>
      );
    }

    switch (input.type) {
      case 'email':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                Email
              </span>
            </label>
            <input type="email" {...commonProps} />
          </div>
        );
      case 'password':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faLock} className="mr-1" />
                Password
              </span>
            </label>
            <input type="password" {...commonProps} />
          </div>
        );
      case 'text':
      case 'search':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={getInputIcon(input.type)} className="mr-1" />
                {input.type.charAt(0).toUpperCase() + input.type.slice(1)}
              </span>
            </label>
            <input type={input.type} {...commonProps} />
          </div>
        );
      case 'date':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                Date
              </span>
            </label>
            <input type="date" {...commonProps} />
          </div>
        );
      case 'checkbox':
        return (
          <div className="form-control">
            <label className="cursor-pointer label">
              <span className="label-text">
                <FontAwesomeIcon icon={faCheck} className="mr-1" />
                Checkbox
              </span>
              <input type="checkbox" checked={input.value === 'on'} readOnly className="checkbox checkbox-primary" />
            </label>
          </div>
        );
      case 'select':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faList} className="mr-1" />
                Select
              </span>
            </label>
            <select className="select select-bordered w-full" value={input.value || ''} readOnly>
              {input.options.map((option, index) => (
                <option key={index} value={option.value}>{option.text}</option>
              ))}
            </select>
          </div>
        );
      case 'file':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faFile} className="mr-1" />
                File
              </span>
            </label>
            <input type="text" value={input.value ? "File Uploaded" : "No File"} className="input input-bordered w-full" readOnly />
          </div>
        );
      case 'number':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faSortNumericUp} className="mr-1" />
                Number
              </span>
            </label>
            <input type="number" {...commonProps} />
          </div>
        );
      case 'tel':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faPhone} className="mr-1" />
                Telephone
              </span>
            </label>
            <input type="tel" {...commonProps} />
          </div>
        );
      case 'url':
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faLink} className="mr-1" />
                URL
              </span>
            </label>
            <input type="url" {...commonProps} />
          </div>
        );
      case 'submit':
        return (
          <button className="btn btn-primary w-full" type="button" disabled>
            {input.value || 'Submit'}
          </button>
        );
      default:
        return (
          <div className="form-control">
            <label className="label">
              <span className="label-text">
                <FontAwesomeIcon icon={faQuestion} className="mr-1" />
                Unknown
              </span>
            </label>
            <input type="text" {...commonProps} />
          </div>
        );
    }
  };

  return (
    <div className="space-y-6">
      {formDetails.map((form, formIndex) => (
        <div key={formIndex} className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">
              <div className="badge badge-primary">{form.method.toUpperCase()}</div>
              {form.action || 'No action specified'}
            </h2>
            <div className="divider mt-2"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {form.inputs.map((input, inputIndex) => (
                <div key={inputIndex} className="flex items-center">
                  {renderFormInput(input)}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormDataDisplay;
