import React, { useState, useContext, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { verifyCode } from '../services/authService';
import { ThemeContext } from '../utils/ThemeContext';
import { AuthContext } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';
import { Turnstile } from '@marsidev/react-turnstile';

const Verify = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { theme } = useContext(ThemeContext);
  const { setSession } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const isSignup = location.state?.isSignup;
  const [turnstileToken, setTurnstileToken] = useState(null);
  const turnstileRef = useRef(null);

  const handleVerify = async (e) => {
    e.preventDefault();
    
    if (!turnstileToken) {
      setError('Please complete the security check');
      return;
    }

    setLoading(true);
    setError(null);

    try {

      const result = await verifyCode(email, code);

      
      if (!result?.session?.access_token || !result?.session?.refresh_token) {
        console.error('Invalid session format:', result);
        throw new Error('Invalid session format received');
      }

      const { data, error: signInError } = await supabase.auth.setSession({
        access_token: result.session.access_token,
        refresh_token: result.session.refresh_token
      });
      
      if (signInError) throw signInError;

      const { data: { session: currentSession } } = await supabase.auth.getSession();


      if (currentSession) {
        navigate('/dashboard');
      } else {
        throw new Error('Session not established after setting');
      }
    } catch (err) {
      console.error('Verification error:', err);
      setError(err.message || 'Failed to verify code');
      // Reset Turnstile after error
      turnstileRef.current?.reset();
      setTurnstileToken(null);
    } finally {
      setLoading(false);
    }
  };

  const onTurnstileSuccess = (token) => {
    setTurnstileToken(token);
  };

  return (
    <div className={`flex min-h-screen ${theme}`}>
      {/* Left side - Illustration (optional, can be added for consistency) */}
      <div className="hidden lg:flex lg:w-1/2 bg-base-200 items-center justify-center p-12">
        <img
          src={`/img/${theme}_ver.svg`}
          alt="Secure Verification Illustration"
          className="max-w-full max-h-full"
        />
      </div>

      {/* Right side - Verification Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md">
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <Link to="/">
              <img
                className="h-32 w-auto"
                src={theme === 'dark' ? "/logo-dark.svg" : "/logo-light.svg"}
                alt="QSI Logo"
              />
            </Link>
          </div>

          {/* Header */}
          <h2 className="text-3xl font-bold mb-8 text-center text-base-content">
            Verify Your Email
          </h2>

          {/* Error Message */}
          {error && (
            <div className="alert alert-error mb-6 shadow-lg">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{error}</span>
            </div>
          )}

          {/* Verification Form */}
          <form onSubmit={handleVerify} className="space-y-6">
            <div className="form-control">
              <div className="relative mb-6">
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter 6-digit code"
                  className="input input-bordered w-full text-center text-2xl tracking-widest bg-base-200 text-base-content"
                  maxLength={6}
                  autoFocus
                />
                <div className="absolute inset-x-0 bottom-0 translate-y-8 text-center">
                  <small className="text-base-content opacity-70">Enter 6-digit verification code</small>
                </div>
              </div>
            </div>

            <div className="flex justify-center mb-4">
              <Turnstile
                ref={turnstileRef}
                siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
                onSuccess={onTurnstileSuccess}
                onError={() => {
                  setError('Security check failed. Please try again.');
                }}
                options={{
                  theme: theme === 'dark' ? 'dark' : 'light',
                }}
              />
            </div>

            <button
              type="submit"
              disabled={loading || code.length !== 6 || !turnstileToken}
              className="btn btn-primary btn-block"
            >
              {loading ? (
                <>
                  <span className="loading loading-spinner"></span>
                  Verifying...
                </>
              ) : (
                'Verify Code'
              )}
            </button>
          </form>

          {/* Try Again Link */}
          <div className="mt-6 text-center">
            <p className="text-base-content opacity-70">
              Didn't receive the code?{' '}
              <button
                onClick={() => navigate('/login')}
                className="text-primary hover:underline font-semibold"
              >
                Try again
              </button>
            </p>
          </div>

          {/* Security Info */}
          <div className="mt-8 p-4 bg-base-300 rounded-lg">
            <div className="flex items-center justify-center text-base-content opacity-70">
              <svg 
                className="w-5 h-5 mr-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8V7a4 4 0 00-8 0v4h8z" 
                />
              </svg>
              <small>
                This code will expire in 10 minutes for security purposes
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify; 