// Create new file: utils/validateUsername.js
import { supabase } from './supabaseClient';

export const validateUsername = async (name, userId) => {
  if (name.length < 3) {
    return "Name must be at least 3 characters long";
  }

  if (name.length > 50) {
    return "Name must be less than 50 characters";
  }

  if (!/^[a-zA-Z0-9\s-_]+$/.test(name)) {
    return "Name can only contain letters, numbers, spaces, hyphens and underscores";
  }

  // Check protected usernames
  try {
    const { data: protectedNames, error: protectedError } = await supabase
      .from('protected_usernames')
      .select('username')
      .match({ username: name.toLowerCase() })
      .single();

    if (protectedError) {
      if (protectedError.code !== 'PGRST116' && protectedError.status !== 406) {
        console.warn('Protected username check warning:', protectedError);
      }
    }

    if (protectedNames) {
      return "This name is not available";
    }
  } catch (error) {
    console.warn('Protected username check failed:', error);
  }

  // Check existing users
  try {
    const { data: existingUser, error: userError } = await supabase
      .from('users')
      .select('id, name')
      .eq('name', name)
      .neq('id', userId)
      .single();

    if (userError && userError.code !== 'PGRST116') {
      console.warn('Existing user check warning:', userError);
    }

    if (existingUser) {
      return "This name is already taken by another user";
    }
  } catch (error) {
    console.warn('Existing user check failed:', error);
    return "Unable to validate username at this time";
  }

  return null;
};