import React, { createContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext();

const DEFAULT_AVATAR = 'https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/570195f7-cd79-4833-4a1f-e75873363300/fullsize';

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (!error && data.session?.user) {
        setSession(data.session);
        setUser(data.session.user);
        checkUser(data.session.user.id);
        if (window.location.pathname === '/') {
          window.location.href = '/dashboard';
        }
      }
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === 'TOKEN_REFRESHED') {

        } else if (event === 'SIGNED_OUT') {
          setSession(null);
          setUser(null);
          setUserData(null);
        }
        
        setSession(session);
        setUser(session?.user ?? null);

        if (event === 'SIGNED_IN' && window.location.pathname === '/') {
          window.location.href = '/dashboard';
        }

        if (session) {
          checkUser(session.user.id);
        }
      }
    );

    // Add a timer to check session validity
    const checkSessionInterval = setInterval(async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session && window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      subscription.unsubscribe();
      clearInterval(checkSessionInterval);
    };
  }, []);

  const checkUser = async (userId) => {
    try {
      // 1. First, fetch system/billing data from main users table
      const { data: systemData, error: systemError } = await supabase
        .from('users')
        .select(`
          id,
          plan_id,
          api_key,
          num_requests,
          last_reset,
          first_login,
          last_sign_in_at
        `)
        .eq('id', userId)
        .single();

      if (systemError && systemError.code !== 'PGRST116') {
        console.error('Error fetching user system data:', systemError);
        return;
      }

      // 2. If user exists, fetch profile data from public view
      if (systemData) {
        const { data: profileData, error: profileError } = await supabase
          .from('user_profiles_public')
          .select('name, avatar_url')
          .eq('id', userId)
          .single();

        if (profileError) {
          console.error('Error fetching user profile:', profileError);
        }

        // Combine system and profile data
        setUserData({
          ...systemData,
          ...profileData
        });
        setIsFirstLogin(systemData.first_login);
      } else {
        // 3. User doesn't exist, create new user
        const isFreeTier = true; // Default to free tier
        const newUser = {
          id: userId,
          name: user.user_metadata.full_name || null,
          avatar_url: DEFAULT_AVATAR,
          api_key: isFreeTier ? null : uuidv4(),
          num_requests: 50,
          last_reset: new Date().toISOString(),
          first_login: true,
          plan_id: 'fd5938fb-a785-4330-9e50-29bec31c6533', // Free Tier plan ID
        };

        // Insert into main users table first
        const { error: insertError } = await supabase
          .from('users')
          .insert([{
            id: userId,
            api_key: newUser.api_key,
            num_requests: newUser.num_requests,
            last_reset: newUser.last_reset,
            first_login: newUser.first_login,
            plan_id: newUser.plan_id
          }]);

        if (insertError) {
          console.error('Error inserting user system data:', insertError);
          return;
        }

        // Then insert profile data using editable view
        const { error: profileError } = await supabase
          .from('user_profiles_editable')
          .insert([{
            id: userId,
            name: newUser.name,
            avatar_url: newUser.avatar_url
          }]);

        if (profileError) {
          console.error('Error inserting user profile:', profileError);
          return;
        }

        setUserData(newUser);
        setIsFirstLogin(true);
      }
    } catch (error) {
      console.error('Error in checkUser:', error);
    }
  };

  const value = {
    session,
    setSession,
    user,
    userData,
    setUserData,
    isFirstLogin,
    setIsFirstLogin
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
