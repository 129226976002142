import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSignOutAlt, 
  faSignInAlt,
  faTimes,
  faPlus,
  faLink,
  faTachometerAlt,
  faHistory,
  faUser,
  faKey,
  faCodeBranch,
  faCreditCard,
  faClipboardList,
  faCog,
  faChevronLeft,
  faChevronRight,
  faRobot
} from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../utils/AuthContext';
import { ThemeContext } from '../utils/ThemeContext';
import { supabase } from '../utils/supabaseClient';

const Sidebar = React.memo(({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const { session } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [activeItem, setActiveItem] = useState('');
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem('sidebarCollapsed');
    return saved ? JSON.parse(saved) : false;
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = useCallback(async () => {
    await supabase.auth.signOut();
    navigate('/');
  }, [navigate]);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(prevState => {
      const newState = !prevState;
      localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
      return newState;
    });
  }, []);

  const menuItems = [
    { path: '/dashboard', icon: faTachometerAlt, text: 'Dashboard', requiresAuth: true },
    { header: 'AI.url', icon: faLink, requiresAuth: false },
    { path: '/aiurl-scan', icon: faPlus, text: 'New Scan', requiresAuth: true },
    { path: '/recent-scans', icon: faHistory, text: 'Recent Scans', requiresAuth: false },
    { header: 'AI.agents', icon: faRobot, requiresAuth: true, comingSoon: true },
    { text: 'Get Started', icon: faPlus, disabled: true, requiresAuth: true },
    { header: 'Settings', icon: faCog, requiresAuth: true },
    { path: '/dashboard/settings/user-details', icon: faUser, text: 'User Details', requiresAuth: true },
    { path: '/dashboard/settings/api-key', icon: faKey, text: 'API Key', requiresAuth: true },
    { path: '/dashboard/settings/webhooks', icon: faCodeBranch, text: 'Webhooks', requiresAuth: true },
    { path: '/dashboard/settings/subscription', icon: faCreditCard, text: 'Subscription', requiresAuth: true },
    { path: '/dashboard/release-notes', icon: faClipboardList, text: 'Release Notes', requiresAuth: true },
  ];

  useEffect(() => {
    // Check if the current path starts with '/results/'
    if (location.pathname.startsWith('/results/')) {
      setActiveItem('/recent-scans');
    } else {
      setActiveItem(location.pathname);
    }
  }, [location]);

  const filteredMenuItems = menuItems.filter(item => !item.requiresAuth || session);

  return (
    <div 
      className={`fixed inset-y-0 left-0 z-30 bg-base-100 overflow-y-auto lg:relative lg:translate-x-0 flex-shrink-0
        border-r border-base-300 transition-all duration-300 ease-in-out transform 
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        ${isCollapsed ? 'w-20' : 'w-64'}`} // Updated width when collapsed
    >
      <div className="flex flex-col h-full relative">
        {/* Collapse toggle button */}
        <button 
          className="absolute -right-6 top-1/2 transform -translate-y-1/2 bg-primary text-primary-content rounded-full p-2 shadow-lg z-40 hidden lg:block"
          onClick={toggleCollapse}
          style={{ boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}
        >
          <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} className="w-4 h-4" />
        </button>

        <div className={`flex flex-col items-center justify-between p-4 pt-8 lg:pt-4 ${isCollapsed ? 'items-center' : ''}`}>
          <div className="flex justify-center w-full">
            <Link to="/" onClick={toggleSidebar}>
              <img 
                src={theme === 'dark' 
                  ? (isCollapsed ? '/logo-dark-q.svg' : '/logo-dark.svg')
                  : (isCollapsed ? '/logo-light-q.svg' : '/logo-light.svg')
                } 
                alt="AI Scanner Logo" 
                className={`h-auto transition-all duration-300 ${isCollapsed ? 'w-20' : 'w-32'}`}
                style={{ maxWidth: isCollapsed ? '5rem' : '8rem' }}
              />
            </Link>
          </div>
          <button 
            className="lg:hidden btn btn-ghost btn-circle mt-4"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <ul className="menu p-2 w-full text-sm">
          {filteredMenuItems.map((item, index) => (
            <li key={item.path || index} className={item.header ? 'mt-4' : ''}>
              {item.header ? (
                <div className={`text-xs font-semibold text-base-content/50 px-4 py-2 flex items-center justify-between ${isCollapsed ? 'px-2' : ''}`}>
                  <span>
                    <FontAwesomeIcon icon={item.icon} className={isCollapsed ? '' : 'mr-2'} />
                    {!isCollapsed && item.header}
                  </span>
                  {!isCollapsed && item.comingSoon && (
                    <span className="text-2xs text-base-content/30">Coming Soon</span>
                  )}
                </div>
              ) : item.disabled ? (
                <div className={`flex items-center px-4 py-2 text-base-content/30 cursor-not-allowed ${isCollapsed ? 'justify-center px-2' : ''}`}>
                  <FontAwesomeIcon icon={item.icon} className={`w-4 h-4 ${isCollapsed ? '' : 'mr-3'}`} />
                  {!isCollapsed && <span className="truncate">{item.text}</span>}
                </div>
              ) : (
                <Link
                  to={item.path}
                  className={`flex items-center px-4 py-2 hover:bg-base-200 rounded-lg transition-colors duration-200
                    ${activeItem === item.path ? 'bg-base-300' : ''} ${isCollapsed ? 'justify-center px-2' : ''}`}
                  onClick={() => {
                    setActiveItem(item.path);
                    toggleSidebar();
                  }}
                >
                  <FontAwesomeIcon icon={item.icon} className={`w-4 h-4 ${isCollapsed ? '' : 'mr-3'}`} />
                  {!isCollapsed && <span className="truncate">{item.text}</span>}
                </Link>
              )}
            </li>
          ))}
        </ul>

        <div className={`mt-auto p-4 ${isCollapsed ? 'flex justify-center' : ''}`}>
          {session ? (
            <button
              onClick={handleLogout}
              className={`btn btn-outline btn-sm ${isCollapsed ? 'btn-circle' : 'btn-block'}`}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className={isCollapsed ? '' : 'mr-2'} />
              {!isCollapsed && 'Logout'}
            </button>
          ) : (
            <Link to="/login" className={`btn btn-primary btn-sm ${isCollapsed ? 'btn-circle' : 'btn-block'}`} onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faSignInAlt} className={isCollapsed ? '' : 'mr-2'} />
              {!isCollapsed && 'Login'}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
