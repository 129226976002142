import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../utils/AuthContext';
import { supabase, checkCompletedScans, fetchNotifications, createNotification, markScanAsNotified, markNotificationAsRead } from '../utils/supabaseClient';
import FeedbackModal from './FeedbackModal';

const DashboardHeader = ({ toggleSidebar }) => {
  const { session, userData } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  const handleNotificationClick = async (notification) => {
    try {
      await markNotificationAsRead(notification.id);
      setNotifications(prevNotifications =>
        prevNotifications.map(n =>
          n.id === notification.id ? { ...n, read: true } : n
        )
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  useEffect(() => {
    if (session) {
      const fetchData = async () => {
        try {


          // Fetch existing notifications
          const existingNotifications = await fetchNotifications(session.user.id);

          setNotifications(existingNotifications);

          // Check for completed scans
          const completedScans = await checkCompletedScans(session.user.id);


          for (const scan of completedScans) {

            try {
              // Check if a notification already exists for this scan
              const notificationExists = existingNotifications.some(n => n.scan_id === scan.job_id);
              if (!notificationExists) {
                const newNotification = await createNotification(
                  session.user.id,
                  `Scan completed for ${scan.original_url}`,
                  scan.job_id
                );

                setNotifications(prev => [newNotification, ...prev]);

                await markScanAsNotified(scan.job_id);

              } else {

              }
            } catch (error) {
              console.error('Error processing scan:', scan, error);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

      // Increase the interval to reduce unnecessary checks
      const intervalId = setInterval(fetchData, 300000); // Check every 5 minutes

      return () => clearInterval(intervalId);
    }
  }, [session]);

  return (
    <header className="bg-base-100 relative z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex-1">
          <button className="lg:hidden btn btn-ghost" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div className="flex items-center space-x-4">
          {session ? (
            <>
              <FeedbackModal />
              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="btn btn-ghost btn-circle">
                  <div className="indicator">
                    <FontAwesomeIcon icon={faBell} />
                    <span className="badge badge-sm indicator-item">
                      {notifications.filter(n => !n.read).length}
                    </span>
                  </div>
                </label>
                <div tabIndex={0} className="mt-3 card card-compact dropdown-content w-80 bg-base-100 shadow-lg z-[100]">
                  <div className="card-body">
                    <span className="font-bold text-lg">{notifications.filter(n => !n.read).length} Unread Notifications</span>
                    <div className="max-h-96 overflow-y-auto">
                      {notifications.map((notification) => (
                        <div key={notification.id} className={`py-2 border-b border-base-300 last:border-b-0 ${notification.read ? 'opacity-50' : ''}`}>
                          <Link
                            to={`/results/${notification.scan_id}`}
                            className="text-sm hover:underline"
                            onClick={() => handleNotificationClick(notification)}
                          >
                            {notification.message}
                          </Link>
                          <p className="text-xs text-base-content/60">
                            {new Date(notification.created_at).toLocaleString()}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="card-actions">
                      <button className="btn btn-primary btn-block">View all</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                  <div className="w-10 rounded-full">
                    <img src={userData?.avatar_url || "https://api.dicebear.com/6.x/initials/svg?seed=AI"} alt="User avatar" />
                  </div>
                </label>
                <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                  <li><button onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Logout
                  </button></li>
                </ul>
              </div>
            </>
          ) : (
            <Link to="/login" className="btn btn-primary btn-sm">
              Login
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
