import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faRobot, faBolt, faCheck, faExternalLinkAlt, faQuoteLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useContext } from 'react';
import { ThemeContext } from '../utils/ThemeContext';
import { supabase } from '../utils/supabaseClient';
import ThreatMap from './ThreatMap';

const LandingPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/f6f562be-b29b-4f3f-8ee6-3bfaaea22200/fullsizee",
    "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/f72a03c2-1960-490f-fdea-7d224ed0ed00/fullsize",
    "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/d03dcfed-4d56-495d-2534-1f1cc3f31800/fullsize",
    "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/944033d4-5dc6-4ec9-4757-247bd5dc5b00/fullsize",
    "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/1ada4974-8331-4571-c98b-eb6664742a00/fullsize",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const [featuresRef, featuresInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [howItWorksRef, howItWorksInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [pricingRef, pricingInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [ctaRef, ctaInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [threatMapRef, threatMapInView] = useInView({ threshold: 0.2, triggerOnce: true });

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const reviews = [
    { name: 'John Doe', company: 'Tech Co', text: 'QSI has been a game-changer for our cybersecurity efforts.' },
    { name: 'Jane Smith', company: 'Security Inc', text: 'The accuracy and speed of this tool are unmatched. Highly recommended!' },
    { name: 'Mike Johnson', company: 'Web Solutions', text: 'We\'ve significantly reduced phishing incidents since implementing QSI.' },
  ];

  const [productFeaturesRef, productFeaturesInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [reviewsRef, reviewsInView] = useInView({ threshold: 0.2, triggerOnce: true });

  const { theme } = useContext(ThemeContext);

  const heroImage = theme === 'dark' 
    ? "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/fb48be82-f6ae-408b-0e6e-49b8bf124500/fullsize"
    : "https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/102fab6a-4c88-458f-7cc7-67fce80daa00/fullsize";

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    const { data, error } = await supabase
      .from('plans')
      .select('id, name, price, interval, stripe_price_id, features, negatives, scan_limit')
      .order('price', { ascending: true });
    if (error) {
      console.error('Error fetching plans:', error);
    } else {
      setPlans(data);
    }
  };

  return (
    <div className="min-h-screen bg-base-100 flex flex-col">
      <Helmet>
        <title>QSI - AI-Powered Cybersecurity Intelligence Platform</title>
        <meta name="description" content="Accelerate your threat detection with QSI's AI-powered cybersecurity tools. Analyze URLs and email attachments with speed and precision." />
      </Helmet>
      <Header />
      
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="hero bg-base-200 py-12 lg:py-20">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
              className="lg:w-1/2"
            >
              <img
                src={heroImage}
                alt="QSI AI Threat Analysis in action"
                className="w-full h-auto"
              />
            </motion.div>
            <div className="lg:w-1/2 lg:pr-8">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-4xl lg:text-5xl font-bold mb-4" 
              >
                Accelerate Threat Detection with AI-Powered Analysis
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-lg lg:text-xl mb-4" 
              >
                Transform your cybersecurity approach with QSI, our cutting-edge AI-driven threat analysis platform. Dramatically reduce analysis time and automate common tasks across various threat vectors, empowering your team to focus on critical security challenges.
              </motion.p>
              <motion.ul
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="mb-8 space-y-2"
              >
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faBolt} className="text-primary mr-2" />
                  <span>Reduce analysis time by up to 90% with AI-powered automation</span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faRobot} className="text-primary mr-2" />
                  <span>Minimize human interaction for routine threat analysis tasks</span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faShieldAlt} className="text-primary mr-2" />
                  <span>Enhance threat detection accuracy across URLs, emails, and more</span>
                </li>
              </motion.ul>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"
              >
                <Link to="/signup" className="btn btn-primary btn-lg w-full sm:w-auto">
                  Start for Free
                </Link>
                <Link to="/recent-scans" className="btn btn-outline btn-lg w-full sm:w-auto">
                  See QSI in Action
                </Link>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Threat Map Section */}
        <section className="py-16 bg-base-100" ref={threatMapRef}>
          <div className="container mx-auto px-4">
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              animate={threatMapInView ? "visible" : "hidden"}
            >
              <ThreatMap />
            </motion.div>
          </div>
        </section>


        {/* Product Features Section */}
        <section className="py-16 bg-base-200" ref={productFeaturesRef}>
          <div className="container mx-auto px-4">
            <motion.div
              variants={staggerChildren}
              initial="hidden"
              animate={productFeaturesInView ? "visible" : "hidden"}
            >
              {/* First row */}
              <motion.div variants={fadeInUp} className="flex flex-col md:flex-row items-center mb-16">
                <div className="md:w-1/2 mb-8 md:mb-0">
                  <img src="https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/031a8112-c350-4ad4-eddf-7f44f4326200/fullsize" alt="AI-powered security" className="max-w-full h-auto" />
                </div>
                <div className="md:w-1/2 md:pl-8">
                  <h2 className="text-3xl font-bold mb-4">AI-Powered Threat Detection</h2>
                  <p className="text-xl">
                    Our advanced AI algorithms analyze URLs and emails in real-time, providing instant threat assessments and protecting your organization from potential cyber attacks.
                  </p>
                </div>
              </motion.div>

              {/* Second row */}
              <motion.div variants={fadeInUp} className="flex flex-col md:flex-row-reverse items-center">
                <div className="md:w-1/2 mb-8 md:mb-0">
                  <img src="https://imagedelivery.net/6ZGbf7gHNt3SvDgc7F0tKA/131fd7d3-f843-4a47-6493-5cb11a0c9300/fullsize" alt="Comprehensive reporting" className="max-w-full h-auto" />
                </div>
                <div className="md:w-1/2 md:pr-8">
                  <h2 className="text-3xl font-bold mb-4">Comprehensive Reporting</h2>
                  <p className="text-xl">
                    Get detailed insights into potential threats with our comprehensive reporting system. Visualize trends, identify patterns, and make data-driven decisions to enhance your cybersecurity posture.
                  </p>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </section>

        {/* Decorative Divider */}
        <div className="flex justify-center py-8 bg-base-200">
          <div className="divider divider-base-content max-w-md">•••</div>
        </div>

        {/* After Product Features Section and before Why Choose QSI Section */}
        <section className="py-16 bg-base-200" ref={featuresRef}>
          <div className="container mx-auto px-4">
            <motion.h2
              variants={fadeInUp}
              initial="hidden"
              animate={featuresInView ? "visible" : "hidden"}
              className="text-3xl font-bold text-center mb-12"
            >
              Why Choose QSI
            </motion.h2>
            <motion.div
              variants={staggerChildren}
              initial="hidden"
              animate={featuresInView ? "visible" : "hidden"}
              className="grid grid-cols-1 md:grid-cols-3 gap-6"
            >
              <motion.div variants={fadeInUp} className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faBolt} className="text-primary mr-2" />
                    Lightning-Fast Analysis
                  </h3>
                  <p>Our AI.nalyst platform delivers rapid results for security alerts and incidents, significantly reducing response times.</p>
                </div>
              </motion.div>
              <motion.div variants={fadeInUp} className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faRobot} className="text-primary mr-2" />
                    AI-Powered Intelligence
                  </h3>
                  <p>Leveraging the latest AI models and LLMs trained on real-world experience to provide accurate threat detection and analysis.</p>
                </div>
              </motion.div>
              <motion.div variants={fadeInUp} className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faShieldAlt} className="text-primary mr-2" />
                    Comprehensive Protection
                  </h3>
                  <p>From URL analysis (AI.url) to email attachment scanning (AI.msg), our tools provide thorough security coverage across multiple vectors.</p>
                </div>
              </motion.div>
              <motion.div variants={fadeInUp} className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faCheck} className="text-primary mr-2" />
                    Advanced Heuristics
                  </h3>
                  <p>Our AI.url platform utilizes hundreds of different heuristics to build a comprehensive risk score for each analyzed website.</p>
                </div>
              </motion.div>
              <motion.div variants={fadeInUp} className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="text-primary mr-2" />
                    Focus on Real Threats
                  </h3>
                  <p>By automating the analysis process, we allow SOC analysts to concentrate their efforts on addressing genuine security risks.</p>
                </div>
              </motion.div>
              <motion.div variants={fadeInUp} className="card bg-base-100 shadow-sm border border-base-200 hover:shadow-md transition-shadow duration-200">
                <div className="card-body">
                  <h3 className="card-title">
                    <FontAwesomeIcon icon={faRobot} className="text-primary mr-2" />
                    Continuous Improvement
                  </h3>
                  <p>Our AI models are constantly updated with the latest threat intelligence, ensuring cutting-edge protection against evolving cyber threats.</p>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </section>

        {/* Pricing Section */}
        <section className="py-16" ref={pricingRef}>
          <div className="container mx-auto px-4">
            <motion.h2
              variants={fadeInUp}
              initial="hidden"
              animate={pricingInView ? "visible" : "hidden"}
              className="text-3xl font-bold text-center mb-4"
            >
              Simple, Transparent Pricing
            </motion.h2>
            <motion.p
              variants={fadeInUp}
              initial="hidden"
              animate={pricingInView ? "visible" : "hidden"}
              className="text-xl text-center mb-12 max-w-2xl mx-auto"
            >
              Choose the plan that fits your needs. All plans include our advanced AI-powered analysis.
            </motion.p>
            <motion.div
              variants={staggerChildren}
              initial="hidden"
              animate={pricingInView ? "visible" : "hidden"}
              className="grid grid-cols-1 md:grid-cols-3 gap-8"
            >
              {plans
                .filter(plan => plan.price > 0)
                .slice(0, 3) // Only show first 3 paid plans
                .map((plan) => (
                  <motion.div
                    key={plan.id}
                    variants={fadeInUp}
                    className={`card ${plan.name === 'Professional' ? 'bg-primary text-primary-content' : 'bg-base-100'} shadow-xl`}
                  >
                    <div className="card-body">
                      <h3 className="card-title">{plan.name}</h3>
                      <p className="text-3xl font-bold">${plan.price}/{plan.interval}</p>
                      <ul className="space-y-2 my-4">
                        {plan.features.map((feature, index) => (
                          <li key={index} className="flex items-center">
                            <FontAwesomeIcon 
                              icon={faCheck} 
                              className={`mr-2 ${plan.name === 'Professional' ? 'text-primary-content' : 'text-success'}`} 
                            />
                            <span>{feature}</span>
                          </li>
                        ))}
                        {plan.negatives && plan.negatives.map((negative, index) => (
                          <li key={`neg-${index}`} className="flex items-center opacity-70">
                            <FontAwesomeIcon 
                              icon={faTimes} 
                              className={`mr-2 ${plan.name === 'Professional' ? 'text-primary-content' : 'text-error'}`} 
                            />
                            <span>{negative}</span>
                          </li>
                        ))}
                      </ul>
                      <div className="card-actions justify-end mt-4">
                        <Link 
                          to="/pricing" 
                          className={`btn ${plan.name === 'Professional' ? 'btn-secondary' : 'btn-primary'}`}
                        >
                          {plan.name === 'Professional' ? 'Choose Pro' : 'Get Started'}
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
            </motion.div>
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              animate={pricingInView ? "visible" : "hidden"}
              className="text-center mt-8"
            >
              <Link to="/pricing" className="link link-primary">
                View all pricing options including our free tier →
              </Link>
            </motion.div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 bg-primary text-primary-content" ref={ctaRef}>
          <div className="container mx-auto px-4 text-center">
            <motion.h2
              variants={fadeInUp}
              initial="hidden"
              animate={ctaInView ? "visible" : "hidden"}
              className="text-3xl font-bold mb-4"
            >
              Start protecting yourself from phishing - for free!
            </motion.h2>
            <motion.p
              variants={fadeInUp}
              initial="hidden"
              animate={ctaInView ? "visible" : "hidden"}
              className="mb-8"
            >
              No credit card required. Start scanning URLs instantly.
            </motion.p>
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              animate={ctaInView ? "visible" : "hidden"}
            >
              <Link to="/pricing" className="btn btn-secondary btn-lg">
                Start Free Trial
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
              </Link>
            </motion.div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;
