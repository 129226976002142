import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const SubscriptionManager = () => {
  const [userPlan, setUserPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showFeatures, setShowFeatures] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchUserPlan();
    }
  }, [user]);

  const fetchUserPlan = async () => {
    try {
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('plan_id, num_requests, last_reset')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;

      if (userData.plan_id) {
        const { data: planData, error: planError } = await supabase
          .from('plans')
          .select('id, name, price, interval, scan_limit, features')
          .eq('id', userData.plan_id)
          .single();

        if (planError) throw planError;

        setUserPlan({ ...userData, ...planData });
      } else {
        setUserPlan(userData);
      }
    } catch (error) {
      console.error('Error fetching user plan:', error);
      // Removed toast notification as per the updated version
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <p className="text-gray-600 dark:text-gray-400">Loading subscription details...</p>;
  }

  if (!userPlan || !userPlan.plan_id) {
    return (
      <div className="text-center py-10 px-6">
        <p className="text-gray-600 dark:text-gray-400 mb-6">No active subscription found. You are on the free plan.</p>
        <button className="btn btn-primary" onClick={() => navigate('/pricing')}>Upgrade Your Plan</button>
      </div>
    );
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Subscription Details</h2>
      <div className="bg-base-200 p-6 rounded-lg shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-xl font-semibold mb-2">{userPlan.name}</h3>
            <div className="badge badge-success mb-2">Active</div>
            <p className="text-gray-700 dark:text-gray-300">${userPlan.price}/{userPlan.interval}</p>
          </div>
          <div>
            <p className="text-gray-600 dark:text-gray-400">Scans</p>
            <p className="text-2xl font-bold">{userPlan.num_requests} / {userPlan.scan_limit}</p>
            <p className="text-sm text-gray-600 dark:text-gray-400">Remaining this {userPlan.interval}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div>
            <p className="text-gray-700 dark:text-gray-300"><strong>Last reset:</strong> {formatDate(userPlan.last_reset)}</p>
            <p className="text-gray-700 dark:text-gray-300"><strong>Next reset:</strong> {formatDate(new Date(new Date(userPlan.last_reset).getTime() + (userPlan.interval === 'month' ? 30 : 365) * 24 * 60 * 60 * 1000))}</p>
          </div>
          {userPlan.features && (
            <div className="collapse bg-base-300">
              <input 
                type="checkbox" 
                checked={showFeatures}
                onChange={() => setShowFeatures(!showFeatures)}
              />
              <div className="collapse-title text-xl font-medium flex items-center">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                Features
                <FontAwesomeIcon 
                  icon={showFeatures ? faChevronUp : faChevronDown} 
                  className="ml-auto"
                />
              </div>
              <div className="collapse-content">
                <ul className="list-disc list-inside">
                  {userPlan.features.map((feature, index) => (
                    <li key={index} className="text-gray-700 dark:text-gray-300">{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <button className="btn btn-primary mt-6" onClick={() => navigate('/pricing')}>
          Change Subscription
        </button>
      </div>
    </div>
  );
};

export default SubscriptionManager;