import { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup, Sphere, Graticule } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { supabase } from '../utils/supabaseClient';

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";
const countryCodesUrl = "https://raw.githubusercontent.com/lukes/ISO-3166-Countries-with-Regional-Codes/master/slim-2/slim-2.json";

const ThreatMap = () => {
  const [threatData, setThreatData] = useState({});
  const [maxThreats, setMaxThreats] = useState(0);
  const [countryCodeMap, setCountryCodeMap] = useState({});

  const colorScale = scaleLinear()
    .domain([0, maxThreats])
    .range(["#ffedea", "#ff5233"])
    .clamp(true);

  const fetchThreatData = async () => {
    const { data, error } = await supabase
      .from('ripper_details')
      .select('geo_data')
      .not('geo_data', 'is', null);

    if (error) {
      console.error('Error fetching threat data:', error);
      return;
    }

    const countryThreats = {};
    
    data.forEach(record => {
      try {
        const geoDataArray = JSON.parse(record.geo_data);
        if (Array.isArray(geoDataArray)) {
          geoDataArray.forEach(location => {
            if (location.countryCode) {
              const code = location.countryCode.toUpperCase();
              countryThreats[code] = (countryThreats[code] || 0) + 1;
            }
          });
        }
      } catch (e) {
        console.error('Error parsing geo_data:', e);
      }
    });
    
    const max = Math.max(...Object.values(countryThreats));
    setMaxThreats(max);
    setThreatData(countryThreats);
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await fetch(countryCodesUrl);
      const data = await response.json();
      
      const codeMap = data.reduce((acc, country) => {
        acc[country.name] = country['alpha-2'];
        return acc;
      }, {});
      
      setCountryCodeMap(codeMap);
    } catch (error) {
      console.error('Error fetching country codes:', error);
    }
  };

  useEffect(() => {
    fetchCountryCodes();
    fetchThreatData();
  }, []);

  const getCountryFill = (countryCode) => {
    if (!threatData[countryCode]) return '#FFE4E1';
    return colorScale(threatData[countryCode]);
  };

  const getCountryCode = (geoProperties) => {
    return geoProperties?.ISO_A2 || 
           geoProperties?.iso2 ||
           geoProperties?.ISO2 ||
           countryCodeMap[geoProperties?.name];
  };

  const getTopCountries = () => {
    return Object.entries(threatData)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)
      .map(([code, count]) => ({
        code,
        count,
        percentage: ((count / getTotalThreats()) * 100).toFixed(1)
      }));
  };

  const getTotalThreats = () => {
    return Object.values(threatData).reduce((sum, count) => sum + count, 0);
  };

  const getAffectedCountries = () => {
    return Object.keys(threatData).length;
  };

  return (
    <div className="text-center py-8">
      <h2 className="text-3xl font-bold mb-4">Global Threat Activity</h2>
      
      <div className="stats stats-vertical sm:stats-horizontal shadow mb-4 w-full sm:w-auto">
        <div className="stat">
          <div className="stat-title">Total Threats</div>
          <div className="stat-value text-primary">{getTotalThreats()}</div>
        </div>
        <div className="stat">
          <div className="stat-title">Affected Countries</div>
          <div className="stat-value text-secondary">{getAffectedCountries()}</div>
        </div>
        <div className="stat">
          <div className="stat-title">Highest Activity</div>
          <div className="stat-value text-accent">
            {getTopCountries()[0]?.percentage}%
          </div>
          <div className="stat-desc">in {getTopCountries()[0]?.code}</div>
        </div>
      </div>

      <div className="w-full aspect-[2/1]">
        <ComposableMap
          projectionConfig={{
            rotate: [-10, 0, 0],
            scale: 147
          }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies
                .filter(geo => 
                  geo.properties.name !== 'Antarctica' && 
                  geo.properties.name !== 'French Southern and Antarctic Lands'
                )
                .map((geo) => {
                  const countryCode = getCountryCode(geo.properties);
                  
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={countryCode ? getCountryFill(countryCode) : "#F5F4F6"}
                      style={{
                        default: {
                          outline: "none",
                          opacity: threatData[countryCode] ? 1 : 0.3
                        },
                        hover: {
                          outline: "none",
                          opacity: threatData[countryCode] ? 1 : 0.3
                        },
                        pressed: {
                          outline: "none",
                          opacity: threatData[countryCode] ? 1 : 0.3
                        }
                      }}
                    />
                  );
                })
            }
          </Geographies>
        </ComposableMap>
      </div>

      <div className="flex justify-between mt-4">
        <span>Low Activity</span>
        <span>High Activity</span>
      </div>
      <div className="w-full h-2 bg-gradient-to-r from-[#ffedea] to-[#ff5233] rounded-full" />

      <div className="overflow-x-auto mt-4">
        <table className="table table-zebra w-full">
          <thead>
            <tr>
              <th>Country</th>
              <th>Threats</th>
              <th>% of Total</th>
            </tr>
          </thead>
          <tbody>
            {getTopCountries().map(({ code, count, percentage }) => (
              <tr key={code}>
                <td>{code}</td>
                <td>{count}</td>
                <td>{percentage}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="space-y-2 mt-4">
        {getTopCountries().map(({ code, count, percentage }) => (
          <div key={code} className="flex items-center gap-2">
            <div className="w-16">{code}</div>
            <progress 
              className="progress progress-primary w-full" 
              value={percentage} 
              max="100"
            ></progress>
            <div className="w-16 text-right">{percentage}%</div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default ThreatMap; 