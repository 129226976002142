import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { submitFeedback } from '../utils/feedbackApi';
import { AuthContext } from '../utils/AuthContext';
import DOMPurify from 'dompurify';
import { Turnstile } from '@marsidev/react-turnstile';
import { ThemeContext } from '../utils/ThemeContext';

const SalesInquiryModal = ({ isOpen, onClose }) => {
  const { session } = useContext(AuthContext);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState(null);
  const { theme } = useContext(ThemeContext);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const turnstileRef = useRef(null);

  const sanitizeMessage = (message) => {
    const sanitized = DOMPurify.sanitize(message, { 
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: []
    });

    const trimmed = sanitized.trim();
    if (trimmed.length < 10) {
      throw new Error('Message must be at least 10 characters long');
    }
    if (trimmed.length > 2000) {
      throw new Error('Message must be less than 2000 characters');
    }

    return trimmed;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!turnstileToken) {
      setFeedbackStatus({
        type: 'error',
        message: 'Please complete the security check'
      });
      return;
    }

    setIsSending(true);
    setFeedbackStatus(null);

    try {
      const sanitizedMessage = sanitizeMessage(feedbackMessage);

      await submitFeedback({
        type: 'sales',
        message: sanitizedMessage,
        userEmail: session?.user?.email || 'anonymous',
        userId: session?.user?.id || 'anonymous',
        turnstileToken,
        session
      });

      setFeedbackStatus({ type: 'success', message: 'Message sent successfully!' });
      setTimeout(() => {
        onClose();
        setFeedbackMessage('');
        setFeedbackStatus(null);
      }, 2000);

      turnstileRef.current?.reset();
      setTurnstileToken(null);
    } catch (error) {
      console.error('Error sending message:', error);
      setFeedbackStatus({ 
        type: 'error', 
        message: error.message || 'Failed to send message. Please try again.' 
      });
    } finally {
      setIsSending(false);
    }
  };

  const onTurnstileSuccess = (token) => {
    setTurnstileToken(token);
  };

  if (!isOpen) return null;

  if (!session?.user) {
    return (
      <dialog className={`modal ${isOpen ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg mb-4">
            <FontAwesomeIcon 
              icon={faHandshake}
              className="mr-2 text-primary"
            />
            Contact Sales Team
          </h3>
          <div className="text-center mb-6">
            <p className="mb-4">Please log in or sign up to submit a sales inquiry.</p>
            <div className="flex gap-2 justify-center">
              <a 
                href="/login?redirect=/sales-inquiry" 
                className="btn btn-primary"
                onClick={onClose}
              >
                Log In
              </a>
              <a 
                href="/signup?redirect=/sales-inquiry" 
                className="btn btn-secondary"
                onClick={onClose}
              >
                Sign Up
              </a>
            </div>
          </div>
          <div className="modal-action">
            <button 
              type="button" 
              className="btn" 
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={onClose}>close</button>
        </form>
      </dialog>
    );
  }

  return (
    <dialog className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box">
        <h3 className="font-bold text-lg mb-4">
          <FontAwesomeIcon 
            icon={faHandshake}
            className="mr-2 text-primary"
          />
          Contact Sales Team
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="form-control w-full mb-4">
            <label className="label">
              <span className="label-text">Message</span>
              <span className="label-text-alt">
                {feedbackMessage.length}/2000
              </span>
            </label>
            <textarea 
              className="textarea textarea-bordered h-24"
              placeholder="Tell us about your needs..."
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
              maxLength={2000}
              required
              aria-label="Sales inquiry message"
            ></textarea>
            <label className="label">
              <span className="label-text-alt text-base-content/60">
                Minimum 10 characters
              </span>
            </label>
          </div>

          {feedbackStatus && (
            <div className={`alert ${feedbackStatus.type === 'success' ? 'alert-success' : 'alert-error'} mb-4`}>
              <span>{feedbackStatus.message}</span>
            </div>
          )}

          <div className="flex justify-center mb-4">
            <Turnstile
              ref={turnstileRef}
              siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
              onSuccess={onTurnstileSuccess}
              onError={() => {
                setFeedbackStatus({
                  type: 'error',
                  message: 'Security check failed. Please try again.'
                });
              }}
              options={{
                theme: theme === 'dark' ? 'dark' : 'light',
              }}
            />
          </div>

          <div className="modal-action">
            <button 
              type="button" 
              className="btn" 
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className={`btn btn-primary ${isSending ? 'loading' : ''}`}
              disabled={isSending || feedbackMessage.length < 10 || !turnstileToken}
            >
              {isSending ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default SalesInquiryModal; 