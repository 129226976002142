import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../utils/supabaseClient';
import { ThemeContext } from '../utils/ThemeContext';
import { AuthContext } from '../utils/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile';
import { initiateAuth } from '../services/authService';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const { theme } = useContext(ThemeContext);
  const { session } = useContext(AuthContext);
  const navigate = useNavigate();
  const [turnstileToken, setTurnstileToken] = useState(null);
  const turnstileRef = useRef(null);

  useEffect(() => {
    if (session) {
      navigate('/dashboard');
    }
  }, [session, navigate]);

  const handleGoogleAuth = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          }
        }
      });
      if (error) throw error;
    } catch (error) {
      console.error('Error during Google authentication:', error);
      setMessage(error.error_description || error.message);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLinkAuth = async (e) => {
    e.preventDefault();
    if (!turnstileToken) {
      setMessage('Please complete the security check');
      setMessageType('error');
      return;
    }

    try {
      setLoading(true);
      await initiateAuth(email, true); // true for signup
      navigate('/verify', { state: { email } });
    } catch (error) {
      console.error('Error during authentication:', error);
      setMessage(error.message);
      setMessageType('error');
    } finally {
      setLoading(false);
      turnstileRef.current?.reset();
      setTurnstileToken(null);
    }
  };

  const onTurnstileSuccess = (token) => {
    setTurnstileToken(token);
  };

  return (
    <div className={`flex min-h-screen ${theme}`}>
      {/* Left side - Illustration */}
      <div className="hidden lg:flex lg:w-1/2 bg-base-200 items-center justify-center p-12">
        <img
          src={`/img/${theme}_ver.svg`}
          alt="Secure Signup Illustration"
          className="max-w-full max-h-full"
        />
      </div>

      {/* Right side - Signup Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md">
          <div className="flex justify-center mb-8">
            <Link to="/">
              <img 
                className="h-32 w-auto"
                src={theme === 'dark' ? "/logo-dark.svg" : "/logo-light.svg"} 
                alt="QSI Logo" 
              />
            </Link>
          </div>
          <h2 className="text-3xl font-bold mb-8 text-center text-base-content">
            Create your account
          </h2>
          {message && (
            <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-error'} mb-4`}>
              <span>{message}</span>
            </div>
          )}
          <div className="flex flex-col space-y-4">
            <button
              onClick={handleGoogleAuth}
              disabled={loading}
              className="btn btn-outline"
            >
              <div className="flex items-center justify-center">
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/>
                </svg>
                Sign up with Google
              </div>
            </button>
            <div className="divider text-base-content opacity-70">OR</div>
            <div className="form-control relative">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-base-content opacity-70 z-10">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full pl-10 bg-base-200 text-base-content"
              />
            </div>
            <div className="flex justify-center">
              <Turnstile
                ref={turnstileRef}
                siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
                onSuccess={onTurnstileSuccess}
                onError={() => {
                  setMessage('Security check failed. Please try again.');
                  setMessageType('error');
                }}
                options={{
                  theme: theme === 'dark' ? 'dark' : 'light',
                }}
              />
            </div>
            <button
              onClick={handleMagicLinkAuth}
              disabled={loading || !turnstileToken}
              className="btn btn-primary"
            >
              Sign up with Magic Link
            </button>
          </div>
          <p className="mt-4 text-center text-base-content opacity-70">
            Already have an account?{' '}
            <Link
              to="/login"
              className="text-primary hover:underline"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;