import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCalendarAlt, faClock, faInfoCircle, faCopy, faExclamationTriangle, faUser, faEye, faEyeSlash, faTags, faShare, faLink, faXTwitter } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../utils/ThemeContext';
import { createPortal } from 'react-dom';
import VoteButtons from '../common/VoteButtons';

const ScanHero = ({ scanData, copyToClipboard, scanUser, showErrorInfo }) => {
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  const getRiskAssessment = (riskScore, processingStatus, status) => {
    if (processingStatus === "ERROR") {
      return {
        title: 'Error Occurred',
        color: 'text-error',
        bgColor: 'bg-error/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-error',
        status: status // Add this line
      };
    } else if (riskScore === null || riskScore === undefined) {
      return {
        title: 'Unknown',
        color: 'text-info',
        bgColor: 'bg-info/20',
        icon: faInfoCircle,
        scoreColor: 'text-info'
      };
    } else if (riskScore > 75) {
      return {
        title: 'High Risk Detected',
        color: 'text-error',
        bgColor: 'bg-error/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-error'
      };
    } else if (riskScore >= 35) {
      return {
        title: 'Moderate Risk Detected',
        color: 'text-warning',
        bgColor: 'bg-warning/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-warning'
      };
    } else {
      return {
        title: 'Low Risk Detected',
        color: 'text-success',
        bgColor: 'bg-success/20',
        icon: faInfoCircle,
        scoreColor: 'text-success'
      };
    }
  };

  // Updated getRiskScore function
  const getRiskScore = useCallback(() => {
    if (scanData.ai_analysis && typeof scanData.ai_analysis === 'object') {
      // First, try to get the risk_score directly from ai_analysis
      if (typeof scanData.ai_analysis.risk_score === 'number') {
        return scanData.ai_analysis.risk_score;
      }

      // If not found, try to parse the analysis string
      if (typeof scanData.ai_analysis.analysis === 'string') {
        try {
          const analysisString = scanData.ai_analysis.analysis.replace(/```json|```/g, '').trim();
          const parsedAnalysis = JSON.parse(analysisString);
          if (typeof parsedAnalysis.risk_score === 'number') {
            return parsedAnalysis.risk_score;
          }
        } catch (error) {
          console.error("Error parsing ai_analysis:", error);
        }
      }
    }
    // If we couldn't get a risk score from ai_analysis, fall back to final_score
    return scanData.final_score;
  }, [scanData]);

  const riskScore = getRiskScore();
  const riskAssessment = getRiskAssessment(riskScore, scanData.processing_status, scanData.status);

  const getResizedImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return null;
    
    // Convert old imagedelivery.net URLs to new static.qsi.net URLs
    const updatedUrl = originalUrl.replace(
      'imagedelivery.net',
      'static.qsi.net/cdn-cgi/imagedelivery'
    );
    
    // Append the 'fullsize' variant
    return `${updatedUrl}/fullsize`;
  }, []);

  useEffect(() => {

  }, [scanUser]);

  const handleTagClick = useCallback((tag) => {
    navigate(`/recent-scans?tag=${encodeURIComponent(tag)}`);
  }, [navigate]);

  const renderTags = (tags) => {
    if (!tags || tags.length === 0) return null;
    return (
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2 flex items-center">
          <FontAwesomeIcon icon={faTags} className="mr-2" />
          Tags
        </h3>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span 
              key={`tag-${index}-${tag}`} // Add a unique key here
              className="badge badge-outline cursor-pointer hover:bg-base-300"
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    );
  };

  let aiAnalysisTags = [];
  if (scanData.ai_analysis) {
    if (typeof scanData.ai_analysis === 'object' && Array.isArray(scanData.ai_analysis.tags)) {
      // New format
      aiAnalysisTags = scanData.ai_analysis.tags;
    } else if (typeof scanData.ai_analysis.analysis === 'string') {
      // Old format
      try {
        const parsedAiAnalysis = JSON.parse(scanData.ai_analysis.analysis.replace(/```json|```/g, ''));
        aiAnalysisTags = parsedAiAnalysis.tags || [];
      } catch (error) {
        console.error("Error parsing ai_analysis for tags:", error);
      }
    }
  }

  const isAlive = (status) => {
    if (typeof status === 'boolean') {
      return status;
    }
    return status === 'alive' || status === 'Alive' || status === true || status === 'true';
  };

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    copyToClipboard(currentUrl);
    setIsShareModalOpen(false);
  };

  const handleTwitterShare = () => {
    const currentUrl = encodeURIComponent(window.location.href);
    const tweetText = encodeURIComponent(`Check out this URL scan result: `);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${currentUrl}`;
    window.open(twitterUrl, '_blank');
    setIsShareModalOpen(false);
  };

  const UserProfileCard = ({ user, navigate }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const triggerRef = useRef(null);

    useEffect(() => {
      if (isHovered && triggerRef.current) {
        const rect = triggerRef.current.getBoundingClientRect();
        const isMobile = window.innerWidth < 768; // Check if mobile

        if (isMobile) {
          // Mobile positioning - centered with margins
          setPosition({
            x: Math.min(window.innerWidth / 2, window.innerWidth - 16), // Center, but keep margin
            y: rect.bottom + window.scrollY + 8 // Add some spacing from the trigger
          });
        } else {
          // Desktop positioning (unchanged)
          setPosition({
            x: rect.left + (rect.width / 2),
            y: rect.bottom + window.scrollY
          });
        }
      }
    }, [isHovered]);

    if (!user) return null;

    const card = isHovered && (
      <div 
        className="fixed z-[100] card card-compact shadow-xl bg-base-100 border border-base-300"
        style={{
          left: position.x,
          top: position.y,
          transform: 'translateX(-50%)',
          width: window.innerWidth < 768 ? 'calc(100% - 32px)' : '24rem', // Adjust width for mobile
          maxWidth: '24rem'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="card-body">
          {/* Header with avatar and name */}
          <div className="flex items-center gap-4 mb-4">
            {user.avatar_url ? (
              <img 
                src={user.avatar_url} 
                alt={`${user.name}'s avatar`}
                className="w-16 h-16 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2"
              />
            ) : (
              <div className="avatar placeholder">
                <div className="bg-neutral text-neutral-content rounded-full w-16">
                  <FontAwesomeIcon icon={faUser} size="lg" />
                </div>
              </div>
            )}
            <div>
              <h3 className="text-xl font-bold">{user.name}</h3>
              <p className="text-sm opacity-70">Member since {new Date(user.created_at).toLocaleDateString()}</p>
            </div>
          </div>

          {/* Stats */}
          <div className="stats stats-vertical lg:stats-horizontal shadow-md w-full bg-base-200">
            <div className="stat">
              <div className="stat-title">Total Scans</div>
              <div className="stat-value text-primary">{user.total_scans || 0}</div>
            </div>
            <div className="stat">
              <div className="stat-title">Reputation</div>
              <div className="stat-value text-secondary">{user.reputation || 0}</div>
            </div>
          </div>

          {/* Recent Activity */}
          <div className="mt-4">
            <h4 className="font-semibold mb-2 text-base-content opacity-70">Recent Activity</h4>
            <div className="space-y-2">
              {(user.recent_scans || []).slice(0, 3).map((scan, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 p-2 rounded-lg hover:bg-base-200 transition-colors cursor-pointer"
                  onClick={() => navigate(`/scan/${scan.job_id}`)}
                >
                  <FontAwesomeIcon icon={faGlobe} className="text-primary" />
                  <span className="text-sm truncate flex-1">{scan.original_url}</span>
                  <span className="text-xs opacity-50">{new Date(scan.timestamp).toLocaleDateString()}</span>
                </div>
              ))}
            </div>
          </div>

          {/* View Profile Button */}
          <button 
            className="btn btn-primary btn-sm mt-4"
            onClick={() => navigate(`/recent-scans?user=${user.id}`)}
          >
            View All Scans
          </button>
        </div>
      </div>
    );

    return (
      <>
        <span
          ref={triggerRef}
          className="hover:text-primary cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {user.name}
        </span>
        {createPortal(card, document.body)}
      </>
    );
  };

  return (
    <div key={`scan-hero-${scanData.job_id}`} className={`card bg-base-100 shadow-md mb-6 overflow-hidden ${riskAssessment.bgColor}`}>
      <div className="card-body p-4 sm:p-6">
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="card-title text-xl sm:text-2xl">Scan Results</h2>
            </div>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faGlobe} className="text-base-content opacity-70 mr-2" aria-hidden="true" />
              <span className="text-base-content break-all text-sm sm:text-base">
                {scanData.original_url}
              </span>
              <button
                onClick={() => copyToClipboard(scanData.original_url)}
                className="btn btn-ghost btn-sm ml-2"
                aria-label="Copy URL to clipboard"
              >
                <FontAwesomeIcon icon={faCopy} aria-hidden="true" />
              </button>
            </div>
            
            {/* Title */}
            {scanData.title && (
              <div className="mb-2 text-base sm:text-lg font-semibold">
                <span className="text-base-content opacity-70">Title: </span>
                {scanData.title}
              </div>
            )}
            
            {/* Avatar, user name, and scan details */}
            <div className="flex flex-col sm:flex-row items-start sm:items-center mt-4">
              {scanUser && scanUser.avatar_url ? (
                <img 
                  src={scanUser.avatar_url} 
                  alt={`${scanUser.name || 'User'}'s avatar`}
                  className="w-8 h-8 rounded-full mr-2"
                />
              ) : (
                <div className="avatar placeholder">
                  <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
                    <FontAwesomeIcon icon={faUser} className="text-base-content" />
                  </div>
                </div>
              )}
              <div className="flex flex-col mt-2 sm:mt-0 sm:ml-2">
                <span className="text-sm font-bold text-base-content">
                  Analyzed by:{' '}
                  {scanUser?.name ? (
                    <UserProfileCard user={scanUser} navigate={navigate} />
                  ) : (
                    <span 
                      className="text-primary hover:text-primary-focus cursor-pointer"
                      onClick={() => navigate('/login')}
                    >
                      Sign in to view
                    </span>
                  )}
                </span>
                <span className="text-xs sm:text-sm text-base-content opacity-70 flex items-center flex-wrap">
                  <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" aria-hidden="true" />
                  Analyzed on: {new Date(scanData.timestamp).toLocaleString()} UTC
                  {scanData.time_to_complete && (
                    <span className="ml-0 sm:ml-4 mt-1 sm:mt-0">
                      <FontAwesomeIcon icon={faClock} className="mr-2" aria-hidden="true" />
                      Analysis time: {scanData.time_to_complete}
                    </span>
                  )}
                </span>
                <span className="text-xs sm:text-sm text-base-content opacity-70 flex items-center mt-1 sm:mt-0">
                  <FontAwesomeIcon
                    icon={scanData.is_private ? faEyeSlash : faEye}
                    className={`mr-1 ${scanData.is_private ? 'text-base-content opacity-50' : 'text-success'}`}
                    aria-hidden="true"
                  />
                  {scanData.is_private ? 'Private' : 'Public'}
                  <span className="mx-2">•</span>
                  <button
                    onClick={handleShare}
                    className="btn btn-ghost btn-xs p-0 flex items-center gap-1"
                    aria-label="Share scan results"
                  >
                    <FontAwesomeIcon icon={faShare} className="opacity-70 hover:opacity-100" />
                    <span className="opacity-70 hover:opacity-100">Share</span>
                  </button>
                </span>
              </div>
            </div>
            
            {/* Tags section */}
            {renderTags(aiAnalysisTags)}
          </div>
          
          {/* Screenshot and Community Score */}
          <div className="mt-4 md:mt-0 md:ml-4 flex-shrink-0 flex flex-col items-center">
            {scanData.screenshot_url && (
              <img
                src={getResizedImageUrl(scanData.screenshot_url)}
                alt="Website Screenshot"
                className="w-full md:w-48 h-36 object-cover rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 mb-4"
              />
            )}
            <div className="text-center bg-base-200 rounded-lg p-2 w-full md:w-56">
              <h3 className="text-sm font-medium text-base-content mb-2 flex items-center justify-center gap-1">
                <FontAwesomeIcon icon={faUser} className="text-primary text-xs" />
                Community Score
              </h3>
              <div className="stats shadow bg-base-100 w-full">
                <div className="stat place-items-center p-2">
                  <VoteButtons 
                    scanId={scanData.id} 
                    scanUserId={scanData.linked_user}
                    className="flex flex-col items-center gap-2"
                    buttonClassName="btn btn-circle btn-sm hover:scale-110 transition-transform duration-200"
                    scoreClassName="stat-value text-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Risk score, status, and risk assessment */}
      <div className="p-4 sm:p-6 bg-base-200">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 justify-items-center">
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-base-content mb-2">Risk Score</h3>
            <div className={`text-3xl font-bold ${riskAssessment.scoreColor}`}>
              {riskScore === null || riskScore === undefined ? 'N/A' : riskScore}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-base-content mb-2">Status</h3>
            {scanData.processing_status === "ERROR" ? (
              <div className="text-lg font-semibold text-error">
                Error: {scanData.status || 'Unknown'}
              </div>
            ) : (
              <div className={`text-lg font-semibold ${
                isAlive(scanData.alive) ? 'text-success' : 'text-error'
              }`}>
                {isAlive(scanData.alive) ? 'Site was Live' : 'Site is Down'}
              </div>
            )}
          </div>
          {showErrorInfo ? (
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-semibold text-base-content mb-2">Error Details</h3>
              <div className="text-sm text-error text-center">
                {scanData.status || 'Unknown error'}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-semibold text-base-content mb-2">Risk Assessment</h3>
              <div className={`text-lg font-semibold ${riskAssessment.color} flex items-center`}>
                <FontAwesomeIcon icon={riskAssessment.icon} className="mr-2" aria-hidden="true" />
                {riskAssessment.title}
              </div>
              {riskAssessment.status && (
                <div className="text-sm text-base-content opacity-70 mt-1">
                  {riskAssessment.status}
                </div>
              )}
            </div>
          )}
        </div>
        
        {showErrorInfo && scanData.error && (
          <div className="mt-4 p-4 bg-error text-error-content rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Error Details</h3>
            <div className="text-sm whitespace-pre-wrap">
              {scanData.error}
            </div>
          </div>
        )}
      </div>

      {/* Share Modal */}
      <dialog id="share_modal" className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg mb-4">Share Scan Results</h3>
          <div className="space-y-4">
            <button
              onClick={handleCopyLink}
              className="btn btn-outline w-full"
            >
              <FontAwesomeIcon icon={faLink} className="mr-2" />
              Copy Link
            </button>
            <button
              onClick={handleTwitterShare}
              className="btn btn-outline w-full"
            >
              <span className="mr-2 font-bold">𝕏</span>
              Share on Twitter
            </button>
          </div>
          <div className="modal-action">
            <button className="btn" onClick={() => setIsShareModalOpen(false)}>Close</button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop" onClick={() => setIsShareModalOpen(false)}>
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
};

export default ScanHero;
