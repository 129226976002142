const API_URL = process.env.REACT_APP_API_URL || 'https://api.qsi.net';

// Helper function to ensure URL is properly formatted
const getApiUrl = () => {
  // If it's already a full URL (starts with http:// or https://), use it as is
  if (API_URL.startsWith('http://') || API_URL.startsWith('https://')) {
    return API_URL.replace(/\/$/, ''); // Remove trailing slash if present
  }
  // Otherwise, add https:// prefix
  return `https://${API_URL.replace(/\/$/, '')}`;
};

export const initiateAuth = async (email, isSignup = false) => {
  const response = await fetch(`${getApiUrl()}/auth/initiate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      isSignup
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'Failed to initiate authentication');
  }

  return await response.json();
};

export const verifyCode = async (email, code) => {
  const response = await fetch(`${getApiUrl()}/auth/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      code
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'Failed to verify code');
  }

  const result = await response.json();
  
  const { supabase } = await import('../utils/supabaseClient');
  
  // Set the initial session
  const { error: sessionError } = await supabase.auth.setSession({
    access_token: result.session.access_token,
    refresh_token: result.session.refresh_token
  });

  if (sessionError) {
    throw new Error('Failed to establish session');
  }

  return result;
}; 