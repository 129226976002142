import { supabase } from './supabaseClient';

const API_BASE = process.env.REACT_APP_API_BASE || 'https://api.qsi.net';
const API_ENDPOINT = `${API_BASE}/api/send-feedback`;

export const submitFeedback = async (feedbackData) => {
  try {
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${feedbackData.session.access_token}`
      },
      body: JSON.stringify(feedbackData),
    });

    if (!response.ok) {
      console.error('feedbackApi: Network response was not ok', response.status, response.statusText);
      throw new Error('Failed to send feedback');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('feedbackApi: Error:', error);
    throw error;
  }
}; 